import React from 'react'
import Layout from '../../shared/components/organisms/layout/layout'
import useMobie from '../../shared/hooks/useMobile'
import BlogExpertContent from '../../components/organisms/blog-expert-content/blog-expert-content'
import BlogExpertContentGrid from '../../components/organisms/blog-expert-content-grid/blog-expert-content-grid'
import SEO from '../../shared/components/atoms/seo/seo';

const BlogexpertTemplate = props => {

  const { pageContext } = props
  const { blogInfo, articles, banners, categoryActive } = pageContext;

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const [isGrid, setIsGrid] = React.useState(false);
  const isMobile = useMobie();
  return (
    <Layout activeMenu={2} modalActive={modalActive} logoMobile={!categoryActive && !isGrid} logoSearch={!categoryActive && !isGrid}>
      <SEO
        title={categoryActive?.metaTitulo || blogInfo?.metaTitulo  || ''}
        description={categoryActive?.metaDescripcion?.metaDescripcion || blogInfo?.metaDescripcion?.metaDescripcion || ''}
        slug={`${blogInfo?.slug}${categoryActive ? categoryActive.redireccionCategoria : ""}`}
      />
      {
        !isGrid ?
          <BlogExpertContent
            blogInfo={blogInfo}
            articles={articles}
            banners={banners}
            categoryActive={categoryActive}
            setModalActive={setModalActive}
            setIsGrid={setIsGrid}
            isMobile={isMobile} />
          :
          <BlogExpertContentGrid
            categoryActive={categoryActive}
            blogInfo={blogInfo}
            articles={articles}
            setIsGrid={setIsGrid}
            isMobile={isMobile} />
      }
    </Layout>
  )
}

export default BlogexpertTemplate
