import React, { useState, useEffect } from 'react'
import BlogHeader from '../../../components/molecules/blog-header/blog-header'
import BlogTitle from '../../../components/molecules/blog-title/blog-title'
import BlogRecentEntries from '../../molecules/blog-recent-entries/blog-recent-entries'
import MostReadArticles from '../../molecules/most-read-articles/most-read-articles'
import BlogSearchResult from '../../molecules/blog-search-result/blog-search-result'
import BlogRecommended from '../../molecules/blog-recomended/blog-recomended'
import ArticleService from '../../../shared/services/article';
import './blog-expert-content.scss'

const BlogExpertContent = (props) => {

  const { blogInfo, articles, setIsGrid, banners, categoryActive, setModalActive, isMobile } = props
  // console.log("BlogExpertContent -> blogInfo", blogInfo)
  const articlesRecommended = articles.filter(article => article.loRecomiendaPetys === true)
  const [articlesMostRead, setArticlesMostRead] = useState(articles)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  // const filterBanners = categoryFilter => categoryFilter ? blogInfo.categoriasBlogDeExpertos.filter(element => element.nombreCategoria.toLowerCase() === categoryFilter)[0].bannerCategoria : blogInfo.banners;

  const SearchArticles = () => {
    return articles.filter(article =>
      article.descripcionCortaDelArticulo && article.descripcionCortaDelArticulo.descripcionCortaDelArticulo.toLowerCase().includes(searchValue) ||
      article.nombreDelArticulo && article.nombreDelArticulo.toLowerCase().includes(searchValue)
    )
  }

  useEffect(() => {

    ArticleService.getReadArticle().then(res => {
      if (res.status === 200) {
        let articlesMostRead = []
        let articlesReaded = res.data.filter(a => a.read > 0)

        articlesReaded.forEach(articleRead => {
          let result = articles.find(article => article.contentful_id === articleRead.cmsId)
          result !== undefined && articlesMostRead.push(result)
        })

        articlesMostRead.length > 0 && setArticlesMostRead(articlesMostRead)
      }

    }).catch(e => console.log(e))

  }, [])

  useEffect(() => {
    setSearchResults(SearchArticles())
  }, [searchValue])

  return (
    <div className="f-blog-container">
      <div className="f-blog-header-continer">
        <BlogHeader
          categoryActive={categoryActive}
          isMobile={isMobile}
          blogInfo={blogInfo}
          bannerInfo={categoryActive ? categoryActive.bannerCategoria : banners}
        />
      </div>
      <div className="f-blog-center-content">
        <BlogTitle
          isMobile={isMobile}
          blogInfo={blogInfo}
          categoryActive={categoryActive}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
        {(searchValue !== '' && searchValue !== ' ') &&
          <BlogSearchResult
            searchValue={searchValue}
            searchResults={searchResults}
          />
        }
        {(searchValue === '' || searchValue === ' ') &&
          <>
            < BlogRecentEntries
              categoryActive={categoryActive}
              articles={articles}
              blogInfo={blogInfo}
              setIsGrid={setIsGrid}
              isMobile={isMobile}
            />
            <MostReadArticles
              categoryActive={categoryActive}
              articlesMostRead={articlesMostRead}
              blogInfo={blogInfo}
              isMobile={isMobile}
            />
            <BlogRecommended
              categoryActive={categoryActive}
              articlesRecommended={articlesRecommended}
              blogInfo={blogInfo}
              isMobile={isMobile}
            />
          </>
        }
      </div>
    </div >
  )
}

export default BlogExpertContent
