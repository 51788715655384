import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import SectionTitleMobile from '../../../shared/components/atoms/section-title-mobile/section-title-mobile'
import BlogRecommended from '../../molecules/blog-recomended/blog-recomended'
import './blog-expert-content-grid.scss'

const BlogExpertContentGrid = (props) => {

  const { blogInfo, articles, setIsGrid, categoryActive, setModalActive, isMobile } = props

  const filterArticlesRecommended = articles => articles.filter(article => article.loRecomiendaPetys === true);

  const defaultactiveOption = { name: 'Más reciente', value: 'mayor' };

  const defaultOptions = [
    {
      name: 'Más reciente',
      value: 'mayor'
    },
    {
      name: 'Más antiguo',
      value: 'menor'
    }
  ]

  const [articlesFiltered, setArticlesFiltered] = useState(articles)
  const [activeSelect, setactiveSelect] = useState(false)
  const [activeOption, setactiveOption] = useState(defaultactiveOption)
  const [options, setOptions] = useState(defaultOptions)

  // const [open, setOpen] = useState(false)
  const orderedArticled = (option) => {
    if (option.value !== activeOption.value) {
      setArticlesFiltered(articlesFiltered.reverse())
      setOptions(options.reverse())
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SectionTitleMobile title="BLOG" redirection={blogInfo.slug} onClick={(e) => { e.preventDefault(); setIsGrid(false) }} className="f-title-mobile-grid" />
      <div className="f-blog-container-grid">
        <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>
        <div className="f-blog-title-container">
          <i class="far fa-arrow-left f-return-button" onClick={() => setIsGrid(false)}></i>
          <h2 className="f-blog-title">Todas las entradas</h2>
        </div>
        <div className="f-blog-description-container">
          <p className="f-blog-description">Encuentra a continuación todos los artículos, por orden de creación en el blog.</p>
          <div className={"f-input-question f-fake-select"} onClick={() => setactiveSelect(true)}>
            <label className="f-label-inside">
              {activeOption.name}
            </label>
            <i class="fal fa-angle-down"></i>
            {activeSelect &&

              <ul className="f-collapse-menu">
                {options.map((option, index) => {

                  return (
                    <li className="f-item-option" key={index} onClick={e => { e.stopPropagation(); setactiveSelect(false); setactiveOption(option); orderedArticled(option) }}>
                      {option.name}
                    </li>
                  )
                })}
              </ul>

            }
          </div>
        </div>
        <div className="f-blog-grid">
          {
            articlesFiltered && articlesFiltered.map((article, key) => {
              return <CardArticle className="f-card-grid" article={article} key={key} category={categoryActive} />
            })
          }
        </div>
      </div>
      <BlogRecommended
        articlesRecommended={filterArticlesRecommended(articles)}
        blogInfo={blogInfo}
        isMobile={isMobile}
        categoryActive={categoryActive}
      />
    </>
  )
}

export default BlogExpertContentGrid
