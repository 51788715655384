import React from 'react'
import Button from '../../../shared/components/atoms/button/button'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import SectionTitleMobile from '../../../shared/components/atoms/section-title-mobile/section-title-mobile'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './blog-header.scss'

const BlogHeader = (props) => {

  const { bannerInfo, blogInfo, categoryActive, isMobile } = props

  /*Banner presentations configuration*/
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  };

  const listItems = bannerInfo && bannerInfo.slice(0, 5).map((banner, key) => {
    return (
      <div className="f-slide-container" key={key}>
        <figure className="f-slider-img">
          <img src={banner?.bannerDelArticulo[isMobile ? 1 : 0]?.file.url} alt={banner?.bannerDelArticulo[isMobile ? 1 : 0]?.description} />
        </figure>
        <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>
        <div className="f-slide-title-content">
          <h1 className="f-slide-title">{banner.nombreDelArticulo}</h1>
          <div className="f-slide-autor-container">
            <p className="f-slide-autor">{banner.autorDelArticulo}</p>
            <Button className={"f-link f-slider-button"} title={banner.textoDeRedireccionBanner} href={blogInfo.slug + banner.slugDelArticulo} />
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="f-blog-header">
      {categoryActive && <div className="f-title-mobile">
        <SectionTitleMobile title="BLOG" redirection={blogInfo.slug} />
      </div>}
      <Slider {...settings} className="f-slider-header">
        {listItems}
      </Slider>
    </div>
  )
}

export default BlogHeader
