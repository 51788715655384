import React, { useRef } from 'react'
import Button from '../../../shared/components/atoms/button/button'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './most-read-articles.scss'

const MostReadArticles = (props) => {

  const { articlesMostRead, blogInfo, categoryActive, isMobile } = props
  const slider = useRef(null);

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  /*Banner presentations configuration*/
  const settings = {
    dots: true,
    infinite: articlesMostRead && articlesMostRead.length > 3,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px 0px 0",
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px 0px 0",
        }
      }
    ]
  };

  return (
    <div className="f-blog-most-read">
      <div className="f-center-content">
        <div className="f-section-title-container">
          <h2 className="f-section-title">{blogInfo.tituloArticulosLeidos}</h2>
          <Button className={"f-link f-see-all"} title={"Ver todos"} href={blogInfo.slug} />
        </div>
        {
          articlesMostRead && articlesMostRead.length > 3 &&
          <Slider {...settings} ref={slider} className="f-slider-most-read">
            {
              articlesMostRead.slice(0, 9).map((article, key) => {
                return (
                  <CardArticle parentSlug={blogInfo.slug} className="f-card-most-read" article={article} type={3} key={key} category={categoryActive} />
                )
              })
            }
          </Slider>
        }
        {
          articlesMostRead && articlesMostRead.length <= 3 && articlesMostRead.length > 0 &&
          <div className="f-list-most-read">
            {
              articlesMostRead.map((article, key) => {
                return (
                  <CardArticle parentSlug={blogInfo.slug} className="f-card-most-read" article={article} type={3} key={key} category={categoryActive} />
                )
              })
            }
          </div>
        }
      </div>
    </div>
  )
}

export default MostReadArticles
