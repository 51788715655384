import React, { useRef } from 'react'
import Button from '../../../shared/components/atoms/button/button'
import CategoryTag from '../../../shared/components/atoms/category-tag/category-tag'
import InteractionArticle from '../../../shared/components/atoms/interaction-article/interaction-article'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import homeLink from '../../../shared/utils/siteUrl'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './blog-recent-entries.scss'

const BlogRecentEntries = (props) => {

  const { articles, blogInfo, setIsGrid, categoryActive, isMobile } = props
  // console.log("BlogRecentEntries -> blogInfo", blogInfo)
  const slider = useRef(null);
  const mainCategory = categoryActive ? categoryActive : (articles.length > 0 && articles[0] && articles[0].categoriasDelArticulo) ? articles[0].categoriasDelArticulo[0] : null

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  /*Banner presentations configuration*/
  const settings = {
    dots: true,
    infinite: articles && articles.length > 3,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px 0px 0",
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px 0px 0",
        }
      }
    ]
  };

  return (
    <div className="f-blog-entries">
      <div className="f-section-title-container">
        <h2 className="f-section-title">{blogInfo.tituloEntradasRecientes}</h2>
        <Button className={"f-link f-see-all"} title={"Ver todos"} href={blogInfo.slug} onClick={() => {
          setIsGrid(true)
        }} />
      </div>
      {articles && (articles.length > 3 || articles.length === 1) &&
        <a className="f-main-article" href={homeLink + blogInfo.slug + articles[0].slugDelArticulo}>
          <figure className="f-main-article-img">
            <InteractionArticle articleId={articles[0].contentful_id} />
            <img src={articles[0].imagenDePrevisualizacionDelArticulo.file.url} alt={articles[0].imagenDePrevisualizacionDelArticulo.description} />
            <div className="f-main-article-category-date">
              {mainCategory && mainCategory !== null && <CategoryTag
                textCategory={mainCategory.nombreCategoria}
                category={mainCategory.nombreCategoria}
                color={mainCategory.colorCategoria}
              />}
              <span className="f-text-bold f-article-date">{articles[0].fechaDePublicacionDelArticulo}</span>
            </div>
          </figure>
          <div className="f-main-article-content">
            {mainCategory && mainCategory !== null && <CategoryTag
              textCategory={mainCategory.nombreCategoria}
              category={mainCategory.nombreCategoria}
              color={mainCategory.colorCategoria}
            />}
            <p className="f-main-article-title">{articles[0].nombreDelArticulo}</p>
            <p className="f-main-article-autor">{articles[0].autorDelArticulo}</p>
            {articles[0].descripcionCortaDelArticulo && <p className="f-main-article-description">{articles[0].descripcionCortaDelArticulo.descripcionCortaDelArticulo}</p>}
            <span className="f-main-read-more">Leer más</span>
          </div>
        </a>
      }
      {articles && articles.length > 3 &&
        <Slider {...settings} ref={slider} className="f-slider-entries">
          {
            articles.slice(1, 10).map((article, key) => {
              return (
                <CardArticle parentSlug={blogInfo.slug} className="f-card-blog" article={article} type={2} key={key} category={categoryActive} />
              )
            })
          }
        </Slider>
      }
      {
        articles && articles.length <= 3 && articles.length > 1 &&
        <div className="f-list-entries">
          {
            articles.map((article, key) => {
              return (
                <CardArticle parentSlug={blogInfo.slug} className="f-card-blog" article={article} type={2} key={key} category={categoryActive} />
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default BlogRecentEntries
