import React from 'react';
import homeLink from "../../../utils/siteUrl";
import './section-title-mobile.scss';


const SectionTitleMobile = ({ redirection, title, className, onClick }) => {

  return (
    <div className={"f-section-title-mobile " + (className ? className : '')}>
      <a href={homeLink + redirection} onClick={onClick} className="f-return-icon"><i className="far fa-arrow-left"></i></a>
      <h3 className="f-section-title">{title}</h3>
    </div>
  )
}

export default SectionTitleMobile
