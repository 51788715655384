import React, { useRef } from 'react'
import homeLink from '../../../shared/utils/siteUrl'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './blog-title.scss'

const BlogTitle = (props) => {

  const { blogInfo, categoryActive, setSearchValue, searchValue, isMobile } = props
  const search = useRef('')

  const onSearch = () => setSearchValue(search.current.value)
  const onEnter = (event) => event.key === 'Enter' ? setSearchValue(search.current.value) : null
  const cleanSearch = () => search.current.value === '' && setSearchValue(search.current.value)

  const getRedirection = (nameCategory) => {
    if (categoryActive && categoryActive.nombreCategoria.toLowerCase() === nameCategory) {
      window.location.href = homeLink + blogInfo.slug
    } else {
      window.location.href = homeLink + blogInfo.slug + nameCategory
    }
  }

  return (
    <div className="f-blog-section-title">

      {
        categoryActive ?
          <div className="f-blog-title-container">
            {!isMobile &&
              <a href={homeLink + blogInfo.slug} className="f-return-icon"><i className="far fa-arrow-left"></i></a>
            }
            <h1 className="f-blog-title">{categoryActive.nombreCategoria}</h1>
          </div>
          :
          <h1 className="f-blog-title">{blogInfo.tituloBlogDeExpertos}</h1>
      }

      < div className="f-blog-description-search">
        <p className="f-blog-decription"> {categoryActive ? categoryActive.descripcionCategoria.descripcionCategoria : blogInfo.descripcionBlogDeExpertos.descripcionBlogDeExpertos}</p>
        <div className="f-searcher-container">
          <input type="text" ref={search} placeholder="¿Qué quieres buscar?" name="seacrh" onKeyPress={onEnter} onChange={cleanSearch} />
          <i className="icon-BUSCADOR f-searcher-icon" onClick={onSearch}></i>
        </div>

      </div>
      {(searchValue === '' || searchValue === ' ') &&
        <div className="f-tabs-container">

          <p className="f-tabs-title">Tema</p>
          <div className="f-tabs">
            {blogInfo.categoriasBlogDeExpertos.map((category, key) => {
              let nameCategory = category.nombreCategoria.toLowerCase()
              let nameCategoryActive = categoryActive && categoryActive.nombreCategoria.toLowerCase()
              return <span className={"f-tab-item " + (nameCategoryActive === nameCategory ? 'active' : '')} key={key} onClick={() => { getRedirection(nameCategory) }}>{category.nombreCategoria}</span>
            })}
          </div>
        </div>
      }

    </div>
  )
}

export default BlogTitle
