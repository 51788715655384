import React, { useRef } from 'react'
import Button from '../../../shared/components/atoms/button/button'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './blog-recomended.scss'

const BlogRecommended = (props) => {

  const { articlesRecommended, blogInfo, categoryActive, isMobile } = props
  const slider = useRef(null);

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  /*Banner presentations configuration*/
  const settings = {
    dots: true,
    infinite: articlesRecommended && articlesRecommended.length > 3,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px 0px 0",
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px 0px 0",
        }
      }
    ]
  };

  return (
    <div className="f-blog-recommended">
      <div className="f-center-content">
        <div className="f-section-title-container">
          <h2 className="f-section-title">{blogInfo.tituloPetysRecomienda}</h2>
          <Button className={"f-link f-see-all"} title={"Ver todos"} href={blogInfo.slug} />
        </div>
        {articlesRecommended && (articlesRecommended.length > 3 || articlesRecommended.length === 1) &&
          <CardArticle parentSlug={blogInfo.slug} className="f-card-main-recommended" article={articlesRecommended[0]} type={3} category={categoryActive} category={categoryActive} />
        }
        {
          articlesRecommended && articlesRecommended.length > 3 &&
          < Slider {...settings} ref={slider} className="f-slider-recommended">
            {articlesRecommended &&
              articlesRecommended.slice(1, 10).map((article, key) => {
                return (
                  <CardArticle parentSlug={blogInfo.slug} className="f-card-recommended" pa article={article} type={2} key={key} category={categoryActive} category={categoryActive} />
                )
              })
            }
          </Slider>
        }
        {
          articlesRecommended && articlesRecommended.length <= 3 && articlesRecommended.length > 1 &&
          <div className={"f-list-recommended " + (articlesRecommended.length === 2 ? 'f-center-card' : '')}>
            {
              articlesRecommended.map((article, key) => {
                return (
                  <CardArticle parentSlug={blogInfo.slug} className="f-card-recommended" article={article} type={2} key={key} category={categoryActive} category={categoryActive} />
                )
              })
            }
          </div>
        }
      </div>
    </div >
  )
}

export default BlogRecommended
