import React from 'react'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import PerritoImg from '../../../assets/images/perrito-buscador.png'
import './blog-search-result.scss'

const BlogSearchResult = (props) => {

  const { searchResults = [], searchValue, setIsGrid, isMobile, className } = props

  if (searchResults && searchResults.length >= 1) {

    return (
      <div className={"f-blog-results-container " + (className ? className : '')}>
        <p className="f-number-results">{`${searchResults.length} Resultados para "${searchValue}"`}</p>
        <div className="f-blog-results">
          {
            searchResults.map((article, key) => {
              return <CardArticle className="f-card-result" article={article} type={2} key={key} />
            })
          }
        </div>
      </div>
    )

  } else {

    return (
      <div className={"f-blog-no-results-container " + (className ? className : '')}>
        <figure className="f-no-result-img">
          <img src={PerritoImg} alt="imgaen de perro - no resultados" />
        </figure>
        <div className="f-no-result-content">
          <h3 className="f-no-result-title">
            Lo sentimos
        </h3>
          <p className="f-no-result-description">
            {`Encontramos 0 resultados para “${searchValue}”. Intenta con otro término o navega por nuestras secciones.`}
          </p>
        </div>
      </div>
    )

  }
}

export default BlogSearchResult
